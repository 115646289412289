<template>
  <!--    FeeSetting Form-->
  <validation-observer
    ref="feePaymentForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`Update Payments - ${fullName}`"
      :show="feePaymentModalOpened"
      @onClose="closeFeeSettingModal()"
    >
      <error-display :error="error" />
      <lenon-select
        v-model="feePayment.fee_setting_id"
        label-name="display_name"
        value-name="id"
        label="Fee"
        placeholder="Select Fee"
        :options="feeSettings"
      />
      <lenon-input
        v-model="feePayment.amount"
        name="amount"
        placeholder="Enter Amount"
        rules="required"
        label="Amount"
      />
      <lenon-date-picker
        v-model="feePayment.date"
        name="date"
        placeholder="Select Date"
        label="Select Date"
        rules="required"
      />
      <lenon-input
        v-model="feePayment.payer_name"
        name="payer_name"
        placeholder="Enter Payer Name"
        label="Payer Name"
      />
      <lenon-input
        v-model="feePayment.payer_phone"
        name="payer_name"
        placeholder="Enter Payer Phone"
        label="Payer Phone"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeFeeSettingModal()"
          />
          <lenon-button
            label="Update"
            :disabled="invalid"
            :loading="feePaymentLoading"
            loading-text="Loading..."
            @onClick="updateFeePayment()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import { BRow } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import {
  UPDATE_FEE_M,
} from '@/graphql/mutations'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'FeeUpdate',
  components: {
    ErrorDisplay,
    LenonDatePicker,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: [Number, String],
      default: null,
    },
    student: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: {},
      feePaymentModalOpened: false,
      feePaymentLoading: false,
      feePayment: {
        id: null,
        fee_setting_id: null,
        amount: null,
        date: null,
        payer_name: null,
        payer_phone: null,
      },
    }
  },
  computed: {
    feeSettings() {
      return this.$store.getters['feeSettings/feeSettings']
    },
    fullName() {
      if (this.student) {
        return this.student.full_name
      }
      return ''
    },
  },
  watch: {
    student(student) {
      this.feePayment = { ...student, date: student.actual_date }
    },
    modalOpened(opened) {
      this.feePaymentModalOpened = opened
    },
  },
  methods: {
    closeFeeSettingModal() {
      this.feePaymentModalOpened = false
      this.$emit('modalClosed')
    },
    updateFeePayment() {
      this.error = {}
      if (this.classId && this.feePayment.id) {
        this.feePaymentLoading = true
        this.$apollo.mutate({
          mutation: UPDATE_FEE_M,
          variables: {
            input: {
              id: this.feePayment.id,
              fee_setting_id: this.feePayment.fee_setting_id,
              date: this.feePayment.date,
              payer_name: this.feePayment.payer_name || 'N/A',
              payer_phone: this.feePayment.payer_phone || 'N/A',
              class_id: this.classId,
              amount: +this.feePayment.amount,
            },
          },
        })
          .then(res => {
            this.showSuccess('Updated fee payment successfully')
            this.feePaymentLoading = false
            this.$store.commit('feePayments/updateFeePayment', res.data.updateFee)
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to update fee payment')
            this.feePaymentLoading = false
          })
      }
    },
  },
}
</script>
