import { GENERATE_RECEIPTS_M } from '@/graphql/mutations'

export default {
  data() {
    return {
      generatingReceipts: false,
    }
  },
  methods: {
    generateReceipt(input) {
      this.generatingReceipts = true
      this.$apollo.mutate({
        mutation: GENERATE_RECEIPTS_M,
        variables: { input },
      }).then(res => {
        this.generatingReceipts = false
        if (res.errors) {
          this.showError('Something went wrong, please try again')
        }
        this.downloadFile(res.data.generateReceipt, 'Receipts.pdf')
      }).catch(err => {
        this.generatingReceipts = false
        this.showError('Something went wrong, please try again')
      })
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = '_blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
  },
}
