<template>
  <div class="academic-years">
    <lenon-list-page
      v-model="searchTerm"
      title="Fees Receipts"
      :columns="columns"
      :rows="feePayments"
      :table-loading="tableLoading"
      :total-rows="totalRows"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :enable-multi-select="true"
      :remote="true"
      search-placeholder="Receipt ID, Student ID, First Name or Last Name"
      :per-page="perPage"
      :show-profile-photo="true"
      @onSearch="handleSearch"
      @onPageChange="handlePageChange"
      @onPerPageChange="handlePerPageChange"
      @onSortChange="handleSortChange"
      @onRowSelect="handleFeesSelected"
    >
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-12"
        >
          <b-collapse
            id="receipts"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedClassId"
                  placeholder="Select Class"
                  :options="classes"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedFeeSettingId"
                  style="max-width: 150px !important;"
                  placeholder="Fee Setting"
                  :options="filteredFeeSettings"
                  label-name="display_name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6 mb-1">
                <div class="row">
                  <div class="col-6">
                    <lenon-button
                      icon="XIcon"
                      label="Delete"
                      variant="outline-danger"
                      tool-tip-text="Delete selected fees"
                      @onClick="deleteSelectedFees()"
                    />
                  </div>
                  <div class="col-6">
                    <lenon-button
                      icon="PrinterIcon"
                      :loading="generatingReceipts"
                      label="Receipt"
                      variant="outline-primary"
                      tool-tip-text="Generate Receipts"
                      @onClick="handleReceiptGeneration"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="d-flex flex-row full-width mb-1 ml-1 text-danger text-italic">
          <strong>
            NB: This table displays a list of all fee payments made by students in any academic year. It provides options to modify, delete and generate receipts for any payment.
          </strong>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedFeeSettingId"
            style="max-width: 150px !important;"
            placeholder="Fee Setting"
            :options="filteredFeeSettings"
            label-name="display_name"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
            icon="XIcon"
            :icon-only="true"
            variant="flat-danger"
            tool-tip-text="Delete selected fees"
            class="mr-1"
            @onClick="deleteSelectedFees()"
          />
          <lenon-button
            icon="PrinterIcon"
            :loading="generatingReceipts"
            label="Receipt"
            icon-only
            tool-tip-text="Generate Receipts"
            @onClick="handleReceiptGeneration"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.receipts
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template #action="{row}">
        <lenon-button
          :icon-only="true"
          icon="Edit2Icon"
          variant="flat-primary"
          tool-tip-text="Change Fee Payment Details"
          @onClick="showFeePaymentModal(row.item)"
        />
      </template>
      <template #amount="{row}">
        {{ row.item.amount | currency }}
      </template>
    </lenon-list-page>
    <fee-update
      :class-id="selectedClassId"
      :modal-opened="feePaymentModalOpened"
      :student="studentFeePayment"
      @modalClosed="closeModal('feePayment')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_FEE_PAYMENTS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import FeeUpdate from '@/views/finance/fee-receipts/FeeUpdate.vue'
import confirm from '@/lenon/mixins/confirm'
import { DELETE_FEE_PAYMENT_M, GENERATE_RECEIPTS_M } from '@/graphql/mutations'
import receipt from '@/lenon/mixins/receipt'
import { feePayments } from '@/store/finance/fee-payments'
import { VBToggle, BCollapse } from 'bootstrap-vue'

export default {
  name: 'FeePayments',
  components: {
    FeeUpdate,
    LenonSelect,
    LenonListPage,
    LenonButton,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast, confirm, receipt],
  data() {
    return {
      initialLoad: false,
      feePaymentModalOpened: false,
      tableLoading: false,
      studentFeePayment: null,
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Student Full Name',
        },
        {
          key: 'receipt_id',
          label: 'Receipt ID',
        },
        {
          key: 'amount',
          label: `Amount Paid(${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'date',
          label: 'Payment Date',
          // sortable: true,
        },
        {
          key: 'payment_mode',
          label: 'Payment Mode',
        },
        {
          key: 'payment_class',
          label: 'Payment Class',
        },
        {
          key: 'payment_type',
          label: 'Fee Type',
        },
        {
          key: 'payer_name',
          label: 'Payer Name',
        },
        {
          key: 'payer_phone',
          label: 'Payer Phone',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      selectedFees: [],
      selectedFeeSettingId: null,
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      searchTerm: null,
      currentPage: 1,
      find: false,
      perPage: 10,
      sort: {
        column: 'date',
        order: 'desc',
      },
    }
  },
  computed: {
    totalRows() {
      return this.payments?.paginatorInfo?.count
    },
    previousSetting() {
      return this.$store.getters['feePayments/previousSettings']
    },
    feePayments() {
      return this.payments?.data
    },
    payments() {
      return this.$store.getters['feePayments/feePayments']
    },
    feeSettings() {
      return this.$store.getters['feeSettings/feeSettings']
    },
    classes() {
      if (!this.selectedTerm) {
        return []
      }
      const classes = this.$store.getters['termsClasses/classes']
      if (this.selectedTerm.tag_id) {
        return classes.filter(c => this.selectedTerm.classes.includes(c.id))
      }
      return classes
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    filteredFeeSettings() {
      return this.feeSettings.filter(fs => {
        if (this.selectedAcademicYearId && this.selectedTermId && this.selectedClassId) {
          return ((fs.academic_year_id === this.selectedAcademicYearId)
              && (fs.term_id === this.selectedTerm.id)
              && (fs.classes.includes(this.selectedClassId)))
        }
        return false
      })
    },
  },
  watch: {
    selectedFeeSettingId(feeSettingId) {
      if (feeSettingId) {
        this.find = false
        this.$store.commit('feePayments/setSettings', {
          selectedFeeSettingId: feeSettingId,
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
          selectedClassId: this.selectedClassId,
          find: this.find,
          search: this.searchTerm,
        })
        this.fetchFeePayments()
      } else if (!this.searchTerm) {
        this.$store.commit('feePayments/setFeePayments', {
          feePayments: { data: [] },
        })
      }
    },
    filteredFeeSettings(feeSettings) {
      this.selectedFeeSettingId = null
      if (!this.searchTerm && !this.initialLoad) {
        this.$store.commit('feePayments/setFeePayments', {
          feePayments: { data: [] },
        })
      }
    },
  },
  mounted() {
    this.initialLoad = true
    this.setSettings()
  },
  methods: {
    handleReceiptGeneration() {
      if (this.selectedFeeSettingId) {
        if (this.selectedFees.length > 0) {
          this.generateReceipt({
            fee_ids: this.selectedFees,
            fee_setting_id: this
              .selectedFeeSettingId,
          })
        } else {
          this.showInfo('Please select fees to generate receipts for.')
        }
      } else {
        this.showInfo('Please select a Fee Setting')
      }
    },
    setSettings() {
      this.searchTerm = this.previousSetting.search
      this.selectedAcademicYearId = this.previousSetting.selectedAcademicYearId
      this.selectedTermId = this.previousSetting.selectedTermId
      this.selectedClassId = this.previousSetting.selectedClassId
      this.find = this.previousSetting.find
      if (this.find) {
        this.handleSearch(this.searchTerm)
      } else {
        setTimeout(() => {
          this.selectedFeeSettingId = this.previousSetting.selectedFeeSettingId
        }, 200)
      }
    },
    handleFeesSelected(items) {
      this.selectedFees = items.map(fs => fs.id)
    },
    deleteSelectedFees() {
      if (this.selectedFees.length < 1) {
        this.showInfo('Please select one or more fees')
      } else {
        this.confirmAction('Deleting fees is not a good idea, please make sure you understand what you are about to do.')
          .then(res => {
            if (res) {
              this.tableLoading = true
              this.$apollo.query({
                query: DELETE_FEE_PAYMENT_M,
                variables: {
                  ids: this.selectedFees,
                },
              })
                .then(res => {
                  this.tableLoading = false
                  if (res.data) {
                    if (!res.data.deleteFees) {
                      return
                    }
                    this.showSuccess('Deleted Successfully')
                    this.$store.commit('feePayments/removeFeePayments', this.selectedFees)
                  }
                })
                .catch(err => {
                  this.showError('Something went wrong, please try again')
                })
            }
          })
      }
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'feePayment':
          this.feePaymentModalOpened = false
          break
      }
    },
    showFeePaymentModal(studentPayment) {
      this.studentFeePayment = studentPayment
      this.feePaymentModalOpened = true
    },
    handleSearch(search) {
      this.searchTerm = search
      this.find = search.length > 0
      this.selectedFeeSettingId = null
      this.$store.commit('feePayments/setSettings', {
        selectedFeeSettingId: this.selectedFeeSettingId,
        selectedAcademicYearId: this.selectedAcademicYearId,
        selectedTermId: this.selectedTermId,
        selectedClassId: this.selectedClassId,
        find: this.find,
        search: this.searchTerm,
      })
      this.fetchFeePayments()
    },
    handlePageChange(page) {
      this.currentPage = page
      this.fetchFeePayments()
    },
    handlePerPageChange(perPage) {
      this.perPage = perPage
      if (!this.initialLoad) {
        this.fetchFeePayments()
      }
    },
    handleSortChange(sort) {
      this.sort.column = sort.sortBy
      this.sort.order = sort.sortDesc ? 'desc' : 'asc'
      this.fetchFeePayments()
    },
    fetchFeePayments() {
      if (this.selectedFeeSettingId || this.searchTerm) {
        this.tableLoading = (this.initialLoad ? this.feePayments.length < 1 : true)
        this.$apollo.query({
          query: FETCH_FEE_PAYMENTS_Q,
          variables: {
            input: {
              fee_setting_id: this.selectedFeeSettingId,
              class_id: this.selectedClassId,
              search: this.searchTerm,
              sort: this.sort,
              find: this.find,
              per_page: this.perPage,
              page: this.currentPage,
            },
          },
        })
          .then(res => {
            if (res.data) {
              this.$store.commit('feePayments/setFeePayments', res.data)
            }
          })
          .catch(err => {
            this.showError('Something went wrong, please try again')
          }).finally(() => {
            this.tableLoading = false
            this.initialLoad = false
          })
      } else {
        this.$store.commit('feePayments/setFeePayments', {
          feePayments: { data: [] },
        })
      }
    },
  },
}
</script>
